/* global google */

import React, { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { compose, withProps } from 'recompose';
import { convertPlacesDataToLocalPlacesModel } from '../helpers/addressHelper';
import loadScript from '../helpers/loadScript';

const SearchBarCustom = ({
  onPlacesChanged,
  className,
  deliveryDetails,
  isNotfocus,
  placeholder = 'Enter your address to check availability',
  addressList,
  onAddressChange
}) => {
  const autocompleteInput = useRef();
  useEffect(() => {
    async function loadAndAddCode() {
      if (!isNotfocus) {
        autocompleteInput.current.focus();
      }
      if (deliveryDetails && deliveryDetails.fromMap) {
        if (autocompleteInput?.current?.value) {
          autocompleteInput.current.value = deliveryDetails.address;
        }
      }
      if (window.google) {
        return;
      }
      await loadScript({
        url: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`
      });
      // addPlacesChangedListener();
    }
    loadAndAddCode();
  }, []);

  const clearInput = () => {
    onAddressChange([]);
    autocompleteInput.current.value = '';
  };

  function handleKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
    }
  }

  const getList = e => {
    if (typeof google === 'object') {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteInput.current.value = e.target.value;
      const autocompleteRequest = {
        input: e.target.value,
        componentRestrictions: { country: 'us' },
        fields: ['description', 'place_id'],
        types: ['address']
      };
      autocompleteService.getPlacePredictions(autocompleteRequest, (results, status) => {
        if (window.google && typeof google?.maps?.places === 'object' && status == google.maps.places.PlacesServiceStatus.OK) {
          onAddressChange(results);
        }
      });
      if (autocompleteInput.current.value == '') {
        onAddressChange([]);
      }
    }
  };

  const selectAddressFromCustomDropDown = placeId => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        placeId
      },
      async function (responses, status) {
        if (status == 'OK') {
          const address = convertPlacesDataToLocalPlacesModel(responses[0]);
          if (!address.postcode) {
            toast.warn('Please select a precise address');
            autocompleteInput.current.value = '';
            onAddressChange([]);
            return false;
          }
          autocompleteInput.current.value = address.address;
          const response = await onPlacesChanged(address);
          if (response === false) {
            autocompleteInput.current.value = '';
          }
          onAddressChange([]);
          return true;
        }
      }
    );
  };

  return (
    <div className={className}>
      <form autoComplete="off" onKeyPress={handleKeyPress} className="custom-address-form">
        <input
          data-testid="#auto_complete_query"
          id="autocompleteQuery"
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          className="form-control"
          onChange={getList}
          ref={autocompleteInput}
        />
        <i className="icon-pin" />
        {addressList && addressList.length ? (
          <button type="button" className="btn " onClick={clearInput}>
            <i className="icon-close fs-12" />{' '}
          </button>
        ) : null}
      </form>
      {/*eslint-disable*/}
      {addressList && addressList.length ? (
        <div className="cs-address-parent" data-testid="#address_list">
          {addressList && addressList.length
            ? addressList.map(item => (
              <div
                className="cs-address-item"
                test-id="#top_address_search_input_container"
                key={item.place_id}
                onClick={() => selectAddressFromCustomDropDown(item.place_id)}
              >
                <i className="icon-pin" />
                {item.description}
              </div>
            ))
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  })
)(SearchBarCustom);
